<template>
  <content-wrapper :title="labels.LBL_PRODUCTS" back>
    <template #actions>
      <el-button size="small" type="success" @click="submitForm('productForm')">
        <i class="el-icon-check mr-1"></i>
        {{ labels.LBL_UPDATE }}
      </el-button>
    </template>

    <el-form
      v-loading="loading"
      :rules="rules"
      ref="productForm"
      :model="product"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item :label="labels.LBL_PHOTO">
            <upload-image v-model="product.image" :expand="true" />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item prop="name" :label="labels.LBL_NAME">
            <el-input v-model="product.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="description" :label="labels.LBL_DESCRIPTION">
            <rich-editor v-model="product.description" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </content-wrapper>
</template>

<script>
  import { labels, rules } from "@/common";
  import { mapState } from "vuex";
  import ContentWrapper from "@/components/layouts/AdminContentWrapper.vue";
  import UploadImage from "@/components/UploadImage.vue";
  import RichEditor from "@/components/RichEditor.vue";

  export default {
    name: "EditProduct",

    components: {
      ContentWrapper,
      UploadImage,
      RichEditor,
    },

    props: {
      productId: {
        type: [String, Number],
        required: true,
      },
    },

    data() {
      return {
        labels,
        rules,

        product: {
          name: "",
          description: "",
          image: null,
        },
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        productDetail: (state) => state.product.productDetail,
      }),
    },

    watch: {
      productId: {
        immediate: true,
        handler(nv) {
          this.loadProductDetail(nv);
        },
      },

      productDetail: {
        handler(nv) {
          this.setProductDetail(nv);
        },
      },
    },

    methods: {
      loadProductDetail(id) {
        this.$store.dispatch("product/getProductDetail", id);
      },

      setProductDetail(detail) {
        this.product = { ...detail, image: detail.image_url };
      },

      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$store.dispatch("product/updateProduct", this.product);
          }
        });
      },
    },
  };
</script>

<style></style>
